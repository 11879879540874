<template>
    <div class="surface-card p-6 border-1 surface-border">
        <data-table-view
            endpoint="user-api-keys"
            headline="API-Schlüssel"
        >
            <template slot="actions">
                <router-link to="/api-keys/create">
                    <Button
                        label="Neuen API-Key erstellen"
                        class="p-button-primary p-button-sm cursor-pointer"
                    />
                </router-link>
            </template>
            <Column field="name" header="Name" sortable>
                <template #body="slotProps">
                    {{ slotProps.data.name }}
                    <template v-if="!slotProps.data.active">
                        <small>
                            (inaktiv)
                        </small>
                    </template>
                </template>
            </Column>
            <Column header="Aktiv">
                <template #body="slotProps">
                    <i class="pi"
                       :class="{
                            'true-icon pi-check-circle': slotProps.data.active,
                        }"
                    ></i>
                </template>
            </Column>
            <Column header="">
                <template #body="slotProps">
                    <router-link
                        v-if="slotProps.data.userCanUpdate"
                        :to="'/api-keys/' + slotProps.data.id"
                    >
                        <Button
                            label="Bearbeiten"
                            class="p-button-secondary p-button-sm cursor-pointer"
                        />
                    </router-link>
                </template>
            </Column>
        </data-table-view>
    </div>
</template>

<script>
    import DataTableView from "@/components/DataTableView";
    import Column from "primevue/column/Column";
    import Button from "primevue/button";

    export default {
        components: {
            Button,
            Column,
            DataTableView,
        },
    }
</script>
